import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const ContentFooter = styled.footer`
  padding: 50px 0;
  background-color: var(--yellowjs);
  display: flex;
  justify-content: space-evenly;
`

const Icon = styled.div`
  width: 42px;
`

const Footer = () => {
  return (
    <ContentFooter>
      <a href="https://linkedin.com/in/raul-lopez-cruz-2898b6100">
        <Icon>
          <StaticImage
            src="../images/linkedin_ico.png"
            alt="linkedin icon"
            objectFit="contain"
            loading="lazy"
            quality={50}
          />
        </Icon>
      </a>
      <a href="https://https://github.com/raullc92">
        <Icon>
          <StaticImage
            src="../images/github_ico.png"
            alt="Github icon"
            objectFit="contain"
            loading="lazy"
            quality={50}
          />
        </Icon>
      </a>
    </ContentFooter>
  )
}

export default Footer
